import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from 'styled-components'


const defaultOptions = [
    { value: "dm", label: "Digital Marketing" },
    { value: "cr", label: "Content Writing" },
    { value: "gd", label: "Graphic Design" },
    { value: "seo", label: "SEO for Business" },
];

const HoneyPotField = styled.input`
    display: none !important;
`


const CTA = ({ id, title, text, buttonText }) => {
    const { register, handleSubmit, watch, errors } = useForm();
    const [isSubmitError, setIsSubmitError] = useState({ status: false, message: '' });
    const [isSuccessfulSubmit, setIsSuccessfulSubmit] = useState(false);

    const submitRequest = (data) => {
        console.log(data);
        sendEmail(data).then((response) => {
            console.log(response);
            if (!response.ok) {
                throw new Error(response.status);
            }
            setIsSubmitError({ status: false, message: '' });
            setIsSuccessfulSubmit(true);
        }).catch(
            err => {
                console.log(err);
                let errMessage = '';

                switch (err) {
                    case 'Error: 401':
                        errMessage = "Honey pot triggered.";
                        break;
                    case 'Error: 402':
                        errMessage = "Incorrect email format";
                        break;
                    default:
                        errMessage = 'Error sending email. Please try again.'
                }

                setIsSubmitError({ status: true, message: errMessage });
                setIsSuccessfulSubmit(false);
            }
        )
    }

    const sendEmail = async (data) => {
        const response = await fetch('/.netlify/functions/sendEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })

        return response
    }

    return (
        <>
            {/* <!-- CTA Area --> */}
            <div id={id} className="cta-section overflow-hidden py-13 py-lg-15 bg-mirage bg-pattern pattern-1 ">
                <Container>
                    <Row className="align-items-center">
                        <Col xl="5" md="6" data-aos="fade-right" data-aos-duration="800">
                            <div className="section-title pr-lg-7 mb-0 dark-mode-texts">
                                <div className="chat-icon circle-xl gr-bg-white-opacity-1 text-green gr-text-4 mb-13">
                                    <i className="icon icon-chat-round-2 text-blue"></i>
                                </div>
                                <h2 className="title gr-text-4 mb-6">
                                    {title}
                                </h2>
                                <p className="gr-text-8 pr-lg-7 mb-0">
                                    {text}
                                </p>
                            </div>
                        </Col>
                        <Col
                            xl="4"
                            lg="5"
                            md="6"
                            className="offset-xl-3 offset-lg-1 mt-9 mt-md-0"
                            data-aos="fade-left"
                            data-aos-duration="800"
                        >

                            <form onSubmit={handleSubmit(submitRequest)} className="cta-form bg-white light-mode rounded-10 px-8 py-8">
                                {!isSuccessfulSubmit && <fieldset>
                                    <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                                        <label htmlFor="ctaName">Name </label>
                                        <input
                                            name="name"
                                            ref={register({
                                                required: true
                                            })}
                                            type="text"
                                            className="form-control gr-text-11"
                                            id="ctaName"
                                            placeholder="i.e. John Doe"
                                            required=""
                                        />
                                        {errors.name?.type === "required" && <p className="error text-red" role="alert">This field is required</p>}
                                    </div>
                                    <HoneyPotField ref={register()} type="checkbox" name="contact_me_by_fax_only" value="1" tabindex="-1" autocomplete="off" />
                                    <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                                        <label htmlFor="ctaEmail">Email </label>
                                        <input
                                            name="email"
                                            ref={register({
                                                required: true,
                                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                            })}
                                            type="email"
                                            className="form-control gr-text-11"
                                            id="ctaEmail"
                                            placeholder="i.e. john@mail.com"
                                        />
                                        {errors.email?.type === "required" && <p className="error text-red" role="alert">This field is required</p>}
                                        {errors.email?.type === "pattern" && <p className="error text-red" role="alert">This field is required</p>}

                                    </div>
                                    <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                                        <label htmlFor="ctaPhone">Phone </label>
                                        <input
                                            name="phone"
                                            ref={register({
                                                required: false
                                            })}
                                            type="text"
                                            className="form-control gr-text-11"
                                            id="ctaPhone"
                                            placeholder="i.e. 0412 000 000"
                                        />
                                    </div>
                                    <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                                        <label htmlFor="message">Message </label>
                                        <textarea
                                            name="message"
                                            ref={register({
                                                required: false
                                            })}
                                            id="message"
                                            className="form-control px-7 gr-text-11 border"
                                            placeholder="Leave us a message"
                                        ></textarea>
                                    </div>
                                    <Button type="submit" className="gr-hover-y rounded-8 w-100">
                                        {buttonText}
                                    </Button>
                                </fieldset>}
                                {isSuccessfulSubmit && <p className="success text-green mb-0" role="alert">Submitted Successfully</p>}
                                {isSubmitError.status && <p className="error text-red mb-0" role="alert">{isSubmitError.message}</p>}
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default CTA;
